import React from 'react'
import {heroBanner, heroBanner__caption, caption__content} from "../scss-modules/splash-banner.module.scss";

export default function SplashBanner({
                                         backgroundImg,
                                         title,
                                         content
                                     }) {
    return (

        <div
            className={heroBanner}
            style={{
                backgroundImage: `url(${backgroundImg})`
            }}>

            <div className={heroBanner__caption}>
                <div className={caption__content}>
                    <h2>{title}</h2>
                    <p>{content}</p>
                </div>
            </div>
        </div>
    )
}
